.css-mb53rw {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  border-radius: var(--radiusMD);
  overflow: hidden;
  background-color: rgb(34, 41, 57);
  transition: background-color 10s;
  cursor: pointer;
  text-decoration: none;

  height: 160px !important;
}
.css-ank6xk {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.css-8b3hir {
  margin-right: auto;
  width: 100%;
  z-index: 1;
  padding: 10px;
}

.css-fbdf7r {
  font-family: Roboto, Lato, sans-serif, Arial, Verdana;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  margin: 0px;
  text-transform: uppercase;
  max-width: calc(100% - 32px);
  height: 40px;
}
.css-17o253n {
  display: flex;
  flex-direction: column;
  gap: var(--spacing0d5Rem);
  margin-top: auto;
}

.css-1av77hb.main-frame-MuiButton-contained {
  color: var(--buttonDefaultTxtPrimary);
  background-color: var(--buttonDefaultBgPrimary);
  text-align: center;
}
.css-1av77hb.main-frame-MuiButton-sizeSmall,
.css-1av77hb.main-frame-MuiButton-sizeSm {
  height: 32px;
  padding: var(--spacing0d6875Rem) var(--spacing1Rem);
  white-space: break-spaces;
}
.rowcha {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}

.active {
	position: relative;
	border: solid 1.5px #ff7b02;
	overflow: hidden;
}

.activeLS {
	position: relative;
	border: solid 1.5px #fa6384;
	overflow: hidden;
}

.bank-error {
	color: #dc3545;
	font-size: 1.2em;
}

.not {
	overflow: hidden;
	border: 1.5px solid transparent;
}

.active .tick {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 25px;
}

.activeLS .tick {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 25px;
}

.not .tick {
	display: none;
}

.btn-custom {
	background-image: linear-gradient(to right, #485563 0%, #29323c  51%, #485563  100%);
	border-radius: 10px;
	border: none;
	transition: 0.5s;
	background-size: 200% auto;
}

.btn-custom:hover {
	background-position: right center; /* change the direction of the change here */
    text-decoration: none;
}

.btn-mg {
	border-radius: 2px;
	margin: 0 2px;
	background-color: #ff7b02;
	border: none;
	padding: 5px 10px;
	color: #fff;
}

.btn-mg:hover {
	background-color: #ffa34d;
	color: #fff;
}

.btn-custom:hover {
	border: none;
	-webkit-box-shadow: -2px -1px 21px -9px rgba(255, 255, 255, 1);
	-moz-box-shadow: -2px -1px 21px -9px rgba(255, 255, 255, 1);
	box-shadow: -2px -1px 21px -9px rgba(255, 255, 255, 1);
}

.card-custom {
	background: #fff;
	cursor: pointer;
	transition: all ease 0.4s;
	margin: 5px;
}

.card-custom:hover {
	transform: scale(1.05);
}

.custom-form {
	color: #000;
}

.title {
	background: -webkit-linear-gradient(#FFCA53, #ff7b02);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.titleLs {
	background: gray;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.titleBA {
	background: #ff2600;
	-webkit-background-clip: text;
	font-size:25px;
	-webkit-text-fill-color: transparent;
}


.highlight {
	color: #ff7b02;
}


.custom-field {
	font-weight: 700;
}

.error {
	color: #dc3545;
}

hr { 
	border-color: #666;
}


.btn-sm{
	border-color: #c7c7c7;
	font-size: 12px;
	margin: 0 3px;
}

.lsImg {
	max-height: 300px;
	width: 100%;
}

.shadow-sm {
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .195) !important;
}

h6 {
	font-size: 13px !important;
}



@media screen and (max-width: 992px) {
	.rowcha {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}
	.inline {
		width: auto;
	}

	
	h5 {
		font-size: 16px;
	}
	.lsImg {
		height: auto;
	}
	.active .tick {
		width: 18px;
	}
	.activeLS .tick {
		width: 18px;
	}
}

@media screen and (max-width:768px) {
	.lsImg {
		height: auto;
		width: 100%;
	}
}

@media screen and (max-width: 550px) {
	* {
		box-sizing: border-box !important;
	}
	.btn-all {
		display: grid;
		grid-template-columns: repeat(3, 1fr) !important;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly
	}
	
	.rowcha {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(1, 1fr);
	}
	.mt-5 {
		margin-top: 1rem !important;
	}
	.notes {
		font-size: 12px !important;
	}
}

@media screen and (max-width: 407px) { 
	* {
		box-sizing: border-box !important;
	}
	.btn-all {
		display: grid;
		grid-template-columns: repeat(3, 100px) !important;
		margin: 0 1px;
	}
}